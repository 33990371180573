'use client';

import { useMutation } from '@tanstack/react-query';
import {
  Bug,
  Home,
  LogOut,
  Monitor,
  Moon,
  School,
  Settings,
  SunMedium,
  SunMoon,
} from 'lucide-react';
import { signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import type { PropsWithChildren, ReactNode } from 'react';
import { Loader } from '~/components/atoms/loader/Loader';
import { Typo } from '~/components/atoms/typography/Typo';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { Skeleton } from '~/components/ui/skeleton';
import { t } from '~/contexts/i18n/I18nServerSide';
import { useModal } from '~/features/modal/ModalProvider';
import { ToggleLanguageMenuSub } from '../i18n/ToggleLanguage';
import { useTheme } from '../theme/ThemeProvider';

export const UserDropdown = (
  props: PropsWithChildren<{ isAdmin?: boolean; children?: ReactNode }>
) => {
  const { enqueueConfirmationModal: enqueueDialog } = useModal();
  const session = useSession();
  const theme = useTheme();

  const mutation = useMutation({
    mutationFn: async () => signOut({ redirect: true, callbackUrl: '/' }),
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{props.children}</DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel className="space-y-1">
          {session.status !== 'loading' ? (
            <>
              <Typo variant="small" className="truncate">
                {session.data?.user.name ?? session.data?.user.email}
              </Typo>
              {session.data?.user.name && (
                <Typo variant="muted" className="truncate">
                  {session.data.user.email}
                </Typo>
              )}
            </>
          ) : (
            <>
              <Skeleton className="h-8 w-full" />
              <Skeleton className="h-8 w-full" />
            </>
          )}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link href="/profile">
            <Settings size={16} className="mr-2" />
            Settings
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <SunMoon className="mr-2 size-4" />
            <span>Theme</span>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <DropdownMenuItem onClick={() => theme.setDark()}>
                <SunMedium className="mr-2 size-4" />
                <span>Dark</span>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => theme.setLight()}>
                <Moon className="mr-2 size-4" />
                <span>Light</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => theme.setSystem()}>
                <Monitor className="mr-2 size-4" />
                <span>System</span>
              </DropdownMenuItem>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
        <ToggleLanguageMenuSub />
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Link href="/home">
            <Home size={16} className="mr-2" />
            Home
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {props.isAdmin ? (
          <>
            <DropdownMenuItem asChild>
              <Link href="/admin">
                <School size={16} className="mr-2" />
                Admin
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </>
        ) : null}
        <DropdownMenuItem asChild>
          <Link href="/help">
            <Bug size={16} className="mr-2" />
            Get help
          </Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={(e) => {
            e.preventDefault();
            enqueueDialog({
              title: t('logoutModalTitle'),
              description: t('logoutModalDescription'),
              actionLabel: t('logout'),
              cancelLabel: t('cancel'),
              onAction: () => {
                mutation.mutate();
              },
            });
          }}
        >
          {mutation.isPending ? (
            <Loader size={16} className="mr-2" />
          ) : (
            <LogOut size={16} className="mr-2" />
          )}
          {t('logout')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
