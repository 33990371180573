'use client';

import { useQueryClient } from '@tanstack/react-query';
import { Languages } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useCookie } from 'react-use';
import {
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/components/ui/dropdown-menu';
import { LANGUAGE } from '~/lib/language.const';

export const ToggleLanguageMenuSub = () => {
  const queryClient = useQueryClient();
  const [, setUserLanguage] = useCookie('user-language');
  const router = useRouter();

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <Languages className="mr-2 size-4" />
        <span>Language</span>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {Object.entries(LANGUAGE).map(([key, { emoji, name }]) => (
            <DropdownMenuItem
              key={key}
              onClick={async () => {
                if (key === 'school') {
                  setUserLanguage('school-default', {});
                } else {
                  setUserLanguage(key, {});
                }

                queryClient.invalidateQueries({
                  queryKey: ['i18n-current-locale'],
                });
                router.refresh();
              }}
            >
              <span className="mr-2 size-4">{emoji}</span>
              <span>{name}</span>
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={async () => {
              setUserLanguage('school-default', {});
              queryClient.invalidateQueries({
                queryKey: ['i18n-current-locale'],
              });
              router.refresh();
            }}
          >
            <span>Default</span>
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
